<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/TechgentsFinalLogo.png" class="l-dark" height="62" alt="">
        <img src="assets/images/Techgentslogowhite.png" class="l-light" height="62" alt="">
      </a>
      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/TechgentsFinalLogo.png" class="l-dark" height="62" alt="">
        <img src="assets/images/Techgentslogowhite.png" class="l-light" height="62" alt="">
      </a>
    </div>
  
   
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->