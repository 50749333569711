<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/software/bg1.png') center center no-repeat; background-size: cover;">
  <div class="bg-overlay"></div>
  <div class="container container-custom">
    <div class="row align-items-center position-relative mt-4" >
      <div class="col-lg-6 col-md-12">
        <div class="title-heading mt-4 text-center text-lg-left">
          <h1 class="heading mb-3 title-dark text-white">TechGents</h1>
          <p class="para-desc text-white-40">A company driven by Technology.</p>
          <div class="mt-4">
            <a href="mailto:info@techgents.nl" class="btn btn-light"><i _ngcontent-vii-c47="" class="mdi mdi-arrow-right"></i> Contact</a>
            </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-12 mt-4 pt-2">
        <div class="position-relative">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

