
 <footer class="footer footer-bar">
   <div class="container text-center">
     <div class="row align-items-center">
       <div class="col-sm-6">
         <div class="text-sm-left">
           <p class="mb-0"><b>KVK / CoC no: </b> <a href="https://www.kvk.nl/orderstraat/product-kiezen/?kvknummer=852206470000">85220647</a>
            <br><b>IBAN: </b> <a href="#">NL91 INGB 0675 1583 46</a>
            <br><b>E-mail: </b> <a href="mailto:info@techgents.nl?SUBJECT=Techgents informatie">info@techgents.nl</a>
         </div>
       </div>
       <!--end col-->
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </footer>
 <!--end footer-->
 <!-- Footer End -->
